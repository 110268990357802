import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Grid from '@material-ui/core/Grid';

import logo from '../images/SG-Logo.svg';

const AboutPage: React.SFC = () => (
  <Layout pageTitle="About">
    <SEO title="About" />
    <Grid container justify="center">
      <Grid item xs={4} style={{ textAlign: 'center', paddingTop: '25px' }} >
        <img style={{ maxWidth: '250px', width: '100%' }} src={logo} alt="Stilson Greene" />
      </Grid>
      <Grid item xs={8} style={{ fontWeight: 400, fontSize: '14px' }}>
        <p>
          I started my graphic design/illustration studio in 1980 in Historic Downtown Leesburg, Virginia.
          I have both regional, national and global clients. My strong suits are logo design/branding,
          ad design/strategy, illustrations and marketing materials.
        </p>
        <p>
          I am an award winning illustrator, painter and editorial cartoonist.
        </p>
        <p><strong>Other stuff:</strong></p>
        <p>
          Who's Who in America, Best of Loudoun Poll Loudoun's Best Graphic Designer 1989 through 2023,
          12 Gold and 6 Silver VPA Awards, 2 National SNA Awards,
          Best Graphic Design Firm and Best Marketing Firm Loudoun Local Poll,
          Recipient of Thomas Balch's John Devine Historical Award,
          Town of Leesburg Ambassador Award and Innovation Award Winner,
          The Leesburg George Marshall Award, Member of the Academies of Loudoun Graphic Arts and Communication
          Board, and recipient of the Rotary Foundation Paul Harris Fellow.
        </p>
        <p>
          Member of the Lemonhead Council,
          Founder of Acoustic on the Green Summer Concert Series,
          Co-founder of Jingle Jam Rock n Roll Christmas Charity Concert for JDRF,
          Songs Stories &amp; Gas Money songwriter music series and organizer of
          Leesburg's Cancer Can Rock Benefit Concert Series.
        </p>
        <p>
          Recorded a critically acclaimed CD of original songs titled Young Lions.
        </p>
      </Grid>
    </Grid>
  </Layout>
);

export default AboutPage;
